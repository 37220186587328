type ProductBox = {
    element: HTMLElement;
    id: string;
    selected: boolean;
    costLfm: number;
    costRoll: number;
    rollLength: number;
    thickness: number;
    height: number;
    specialHeight: number;
};

class ProductLine {
    private container: HTMLElement;
    public productLines: ProductBox[] = [];
    public currentProduct: ProductBox;

    constructor(container: HTMLElement) {
        this.container = container;
        this.container.querySelectorAll(".product-box").forEach((productBox: HTMLElement) => {
            const product = {
                element: productBox,
                id: productBox.dataset.id,
                selected: productBox.classList.contains("selected"),
                costLfm: Number(productBox.dataset.costLfm),
                costRoll: Number(productBox.dataset.costRoll),
                rollLength: Number(productBox.dataset.rollLength),
                thickness: Number(productBox.dataset.thickness),
                height: Number(productBox.dataset.height),
                specialHeight: Number(productBox.dataset.specialHeight),
            };

            this.productLines.push(product);

            if (product.selected) {
                this.currentProduct = product;
            }
        });
    }

    public setCurrentProductLine(id) {
        this.productLines.forEach((product) => {
            if (product.selected) {
                this.currentProduct = product;
                return;
            }
        });
    }
}

export { ProductBox, ProductLine };
