const calculateCost = (length: number, amount: number, height: number, squareMeterCost: number) => {
    return (height / 1000) * length * amount * squareMeterCost;
};

const applyDiscount = (cost: number, discount: number) => {
    return cost * ((100 - discount) / 100);
};

const getCurrencyOutputString = (cost: number) => {
    return cost.toLocaleString("de-AT", {
        style: "currency",
        currency: "EUR",
    });
};

const initOverlay = (overlay: HTMLElement) => {
    const formSection = overlay.querySelector(".form-section");
    formSection.addEventListener("click", (e) => {
        e.stopPropagation();
    });

    const closeButton = overlay.querySelector(".close");

    closeButton.addEventListener("click", () => {
        overlay.style.display = "none";
    });

    document.addEventListener("keydown", (event: KeyboardEvent) => {
        if (overlay.style.display !== "none" && event.key === "Escape") {
            overlay.style.display = "none";
        }
    });

    overlay.addEventListener("click", () => {
        overlay.style.display = "none";
    });
};

const displayError = (button: HTMLButtonElement, message) => {
    button.classList.remove("loading");
    button.disabled = false;
    const error = document.createElement("p");
    error.classList.add("errors", "small-text");
    console.log(message);
    error.innerHTML = message.toString();
    button.parentNode.appendChild(error);
};

export { calculateCost, applyDiscount, getCurrencyOutputString, initOverlay, displayError };
