import { ProductBox, ProductLine } from "./configurator/ProductLine";
import { Color, ColorSelector } from "./configurator/ColorSelector";
import { Amount } from "./configurator/Amount";
import { Cart } from "./Cart";
import { CartOverview } from "./configurator/CartOverview";
import { displayError } from "./utility/helper";

export default class Configurator {
    private cart: Cart = new Cart();
    private currentStep: number = 0;
    private steps: string[] = ["productLine", "color", "amount", "cart", "thankYou"];
    private container: HTMLElement;
    private stepContainer: HTMLElement;
    private continueButtons: NodeListOf<HTMLButtonElement>;
    private backButtons: NodeListOf<HTMLButtonElement>;
    private gotoCartButtons: NodeListOf<HTMLButtonElement>;
    private thankYouBackButton: HTMLButtonElement;
    private numberOfItems: NodeListOf<HTMLElement>;

    private productLineButtons: NodeListOf<HTMLButtonElement>;

    private offset = 100;
    private productLineStep: ProductLine;
    private colorStep: ColorSelector;
    private amountStep: Amount;
    private cartStep: CartOverview;
    private overlay: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.stepContainer = this.container.querySelector(".step-container");
        this.continueButtons = this.container.querySelectorAll(".button-group > .continue");
        this.backButtons = this.container.querySelectorAll(".button-group > .back");
        this.gotoCartButtons = document.querySelectorAll(".goto-cart");
        this.thankYouBackButton = this.container.querySelector(".thank-you > .step-content > .back-button");
        this.numberOfItems = this.container.querySelectorAll(".number-of-items");

        this.productLineButtons = document.querySelectorAll(".buy-product-line");

        this.productLineStep = new ProductLine(this.container.querySelector("#Step1"));
        this.colorStep = new ColorSelector(
            this.container.querySelector("#Step2"),
            this.productLineStep.currentProduct.id
        );
        this.amountStep = new Amount(this.container.querySelector("#Step3"));
        const step4 = this.container.querySelector("#Step4");
        if (step4) {
            this.cartStep = new CartOverview(this.container.querySelector("#Step4"));
        }

        this.overlay = document.querySelector(".overlay-login");

        this.initNavigationButtons();
        this.initSteps();
        this.addSavedItemsToCart();
        this.updateItemsInCart();
        this.initOverlay();

        const queryString = new URLSearchParams(window.location.search);
        if (queryString.has("step")) {
            const stepIndex = this.steps.indexOf(queryString.get("step"));
            if (stepIndex >= 0 && stepIndex < this.steps.length && (stepIndex < 3 || this.cartStep)) {
                this.currentStep = stepIndex;
                this.scrollStepIntoView();
            }
        }
    }

    private initNavigationButtons() {
        this.continueButtons.forEach((button) => {
            button.addEventListener("click", () => {
                this.nextStep();
            });
        });

        this.backButtons.forEach((button) => {
            button.addEventListener("click", () => {
                this.prevStep();
            });
        });

        this.gotoCartButtons.forEach((button) => {
            button.addEventListener("click", () => {
                this.currentStep = this.steps.indexOf("cart");
                location.href = "#" + this.container.id;
                this.scrollStepIntoView();
            });
        });

        this.thankYouBackButton?.addEventListener("click", () => {
            this.currentStep = this.steps.indexOf("productLine") || 0;
            this.scrollStepIntoView();
        });

        this.cartStep?.continueShoppingButton.addEventListener("click", () => {
            this.currentStep = this.steps.indexOf("color") || 1;
            this.scrollStepIntoView();
        });

        this.productLineButtons.forEach((button) => {
            button.addEventListener("click", (event) => {
                this.changeProductLine((event.target as HTMLButtonElement).dataset.productLine);
                location.href = "#" + this.container.id;
                this.currentStep = this.steps.indexOf("color") || 1;
                this.scrollStepIntoView();
            });
        });
    }

    private initOverlay() {
        if (this.overlay) {
            const formSection = this.overlay.querySelector(".form-section");
            formSection.addEventListener("click", (e) => {
                e.stopPropagation();
            });

            const closeButton = this.overlay.querySelector(".close");

            closeButton.addEventListener("click", () => {
                this.overlay.style.display = "none";
            });

            document.addEventListener("keydown", (event: KeyboardEvent) => {
                if (this.overlay.style.display !== "none" && event.key === "Escape") {
                    this.overlay.style.display = "none";
                }
            });

            this.overlay.addEventListener("click", () => {
                this.overlay.style.display = "none";
            });
        }
    }

    private initSteps() {
        this.productLineStep.productLines.forEach((productBox: ProductBox) => {
            productBox.element.addEventListener("click", () => {
                if (productBox.element.classList.contains("selected")) {
                    this.nextStep();
                }
                this.changeProductLine(productBox.id);
            });
        });

        this.colorStep.previews.forEach((preview) => {
            preview.element.addEventListener("click", () => {
                this.changeProductLine(preview.id);
            });
        });

        this.colorStep.colors.forEach((colors) => {
            colors.colors.forEach((color) => {
                color.element.addEventListener("click", () => {
                    if (color.element.classList.contains("selected")) {
                        this.nextStep();
                    }
                    this.changeColor(color, colors.id);
                });
            });
        });

        this.initAmountStep();

        this.addRemoveItemEventListener();

        this.initOrderButtons();
    }

    private initOrderButtons() {
        this.cartStep.loginToOrderButton?.addEventListener("click", (event) => {
            this.overlay.style.display = "block";
        });

        this.cartStep.orderButton?.addEventListener("click", async (event) => {
            const button = event.currentTarget as HTMLButtonElement;
            button.classList.add("loading");
            button.disabled = true;

            const url = "/actions/order-confirmation-module/order-confirmation/";
            const response = await fetch(url, {
                method: "GET",
            });
            if (response.status === 500) {
                displayError(button, response.statusText);
            } else {
                const result = await response.json();
                if (result.error) {
                    displayError(button, result.error);
                } else {
                    button.classList.remove("loading");
                    button.disabled = false;
                    this.currentStep = this.steps.indexOf("thankYou");
                    this.scrollStepIntoView();
                    this.cart.deleteCart();
                    this.cartStep.removeCartItems();
                    this.updateItemsInCart();
                }
            }
        });
    }

    private addRemoveItemEventListener() {
        this.cartStep?.deleteItemButtons.forEach((button) => {
            button.removeEventListener("click", (event) => {
                this.removeFromCart(event.currentTarget as HTMLButtonElement);
            });
            button.addEventListener("click", (event) => {
                this.removeFromCart(event.currentTarget as HTMLButtonElement);
            });
        });
    }

    private initAmountStep() {
        this.amountStep.rolls.increase.addEventListener("click", () => {
            this.amountStep.changeRollsAmount(1);
        });
        this.amountStep.rolls.decrease.addEventListener("click", () => {
            this.amountStep.changeRollsAmount(-1);
        });

        this.amountStep.lfmMobile.increase.addEventListener("click", () => {
            this.amountStep.changeLfmAmountByValue(0.5);
        });
        this.amountStep.lfmMobile.decrease.addEventListener("click", () => {
            this.amountStep.changeLfmAmountByValue(-0.5);
        });
        this.amountStep.inputLfmCut.addEventListener("input", (event) => {
            this.amountStep.changeLfmAmountAbs(Number((event.currentTarget as HTMLInputElement).value));
        });

        this.amountStep.setSelectedItem(this.productLineStep.currentProduct, this.colorStep.currentColor);

        this.amountStep.addRollsToCart.addEventListener("click", () => {
            this.addRollsToCart();
        });
        this.amountStep.addLfmToCart.addEventListener("click", () => {
            this.addCutToCart();
        });
        this.amountStep.addSampleToCart.addEventListener("click", () => {
            this.addSampleToCart();
        });
        this.amountStep.addAccessoiresToCart.forEach((addAccessory) => {
            addAccessory.addEventListener("click", (event) => {
                this.addAccessoryToCart(event.target as HTMLElement);
            });
        });
        this.amountStep.specialSizeButton.addEventListener("click", () => {
            this.toggleSpecialSizeButton();
        });
    }

    private changeProductLine(productLineID: string) {
        this.changeColor(this.colorStep.getSelectedColorForProductLine(productLineID), productLineID);

        this.changeSelectionState(this.productLineStep.productLines, "selected", productLineID);
        this.changeSelectionState(this.colorStep.previews, "invisible", productLineID);
        this.changeSelectionState(this.colorStep.selections, "selected", productLineID);
        this.changeSelectionState(this.colorStep.colors, "selected", productLineID);

        this.setCurrentSelection(productLineID, this.colorStep.currentColor.id);
    }

    private changeColor(color: Color, productLineID: string) {
        this.colorStep.selections.forEach((selection) => {
            if (selection.id === productLineID) {
                selection.image.src = color.image.src.replace("_colorThumb", "");
                selection.image.alt = color.image.alt;
                selection.element.querySelector(".title").innerHTML = color.id;
            }
        });

        this.colorStep.colors.forEach((colors) => {
            if (colors.id === productLineID) {
                this.changeSelectionState(colors.colors, "selected", color.id);
            }
        });

        this.setCurrentSelection(productLineID, color.id);
    }

    private changeSelectionState(list, className, productLineID) {
        list.forEach((entry) => {
            entry.selected = entry.id === productLineID;
            entry.element.classList.toggle(className, entry.selected);
        });
    }

    private setCurrentSelection(productLineId, colorId) {
        if (this.productLineStep.currentProduct.id !== productLineId) {
            this.productLineStep.setCurrentProductLine(productLineId);
        }
        if (this.colorStep.currentColor.id !== colorId) {
            this.colorStep.setCurrentColor(productLineId, colorId);
        }

        this.amountStep.setSelectedItem(this.productLineStep.currentProduct, this.colorStep.currentColor);
    }

    private nextStep() {
        if (this.currentStep < this.steps.length) {
            this.currentStep++;
            this.scrollStepIntoView();
        }
    }

    private prevStep() {
        if (this.currentStep > 0) {
            this.currentStep--;
            this.scrollStepIntoView();
        }
    }

    private scrollStepIntoView() {
        const delta = this.currentStep * this.offset;
        this.stepContainer.style.transform = `translateX(-${delta}vw)`;
    }

    private addRollsToCart() {
        this.addColorToCart({ rolls: Number(this.amountStep.rolls.current.innerHTML) });
    }

    private addCutToCart() {
        this.addColorToCart({ lfm: Number(this.amountStep.lfmDesktop.innerHTML) });
    }

    private addSampleToCart() {
        this.addColorToCart({ sample: 1 });
    }

    private addColorToCart(amount) {
        const idExtension = this.amountStep.selectedItem.specialSizeSelected ? "-S" : "";
        const entry = {
            id: this.colorStep.currentColor.itemNumber + idExtension,
            color: this.getColorProperties(),
            amount: amount,
            cost: {},
        };
        this.addToCart(entry, this.amountStep.selectedItem.element);
    }

    private addAccessoryToCart(target: HTMLElement) {
        const entry = {
            id: target.dataset.id,
            accessory: {
                id: target.dataset.id,
                name: target.dataset.description,
                price: Number(target.dataset.price),
                amount: target.dataset.amount,
                deliveryTime: target.dataset.deliveryTime,
                image: target.dataset.imageUrl.replace("/_accessoryThumb", ""),
            },
            amount: {
                piece: 1,
            },
            cost: {},
        };
        this.addToCart(entry, target.parentElement.querySelector("img"));
    }

    private addToCart(entry, element: HTMLElement) {
        const cartItem = this.cart.addToCart(entry);
        this.cartStep?.addCartItem(cartItem, cartItem != entry);
        this.flyElementToCart(element);
        this.updateItemsInCart();
        this.addRemoveItemEventListener();
    }

    private getColorProperties() {
        return {
            id: this.colorStep.currentColor.itemNumber,
            code: this.colorStep.currentColor.itemCode,
            name: this.colorStep.currentColor.id,
            configRollCost: this.amountStep.selectedItem.configRollCost,
            configCutCost: this.amountStep.selectedItem.configCutCost,
            configOrderCost: this.amountStep.selectedItem.configOrderCost,
            deliveryTime: this.amountStep.selectedItem.deliveryTime,
            image: this.colorStep.currentColor.image.src.replace("/_colorThumb", ""),
            specialSizeSelected: this.amountStep.selectedItem.specialSizeSelected,
            pricePerSquareMeter: this.productLineStep.currentProduct.costLfm,
            pricePerSquareMeterRoll: this.productLineStep.currentProduct.costRoll,
            rollLength: this.productLineStep.currentProduct.rollLength,
            thickness: this.productLineStep.currentProduct.thickness,
            height: this.productLineStep.currentProduct.height,
            specialHeight: this.productLineStep.currentProduct.specialHeight,
            product: this.productLineStep.currentProduct.id,
        };
    }

    private addSavedItemsToCart() {
        this.cart.getCart().forEach((item) => {
            this.cartStep?.addCartItem(item, false);
        });
        this.addRemoveItemEventListener();
    }

    private updateItemsInCart() {
        this.numberOfItems.forEach((element) => {
            element.innerHTML = this.cart.getCart().length.toString();
        });
    }

    private flyElementToCart(element: HTMLElement) {
        const copy = element.cloneNode(true) as HTMLElement;
        const step3Position = this.amountStep.getContainer().getBoundingClientRect();
        const elementPosition = element.getBoundingClientRect();
        const cartPosition = this.amountStep.cartIcon.getBoundingClientRect();
        copy.classList.add("zoom-to-cart");
        this.amountStep.getContainer().appendChild(copy);
        copy.style.top = (elementPosition.top - step3Position.top).toString() + "px";
        copy.style.left = (elementPosition.left - step3Position.left).toString() + "px";
        setTimeout(() => {
            copy.style.opacity = "0.1";
            copy.style.transformOrigin = "top left";
            copy.style.transform = `translate(${cartPosition.left - elementPosition.left}px, ${
                cartPosition.top - elementPosition.top
            }px ) scale(0.1)`;
            setTimeout(() => {
                copy.remove();
            }, 600);
        }, 0);
    }

    private removeFromCart(button: HTMLButtonElement) {
        this.cart.removeFromCart(button.dataset.id);
        this.cartStep?.removeCartItem(button);
        this.updateItemsInCart();
    }

    private toggleSpecialSizeButton() {
        this.amountStep.setSpecialHeight(this.productLineStep.currentProduct, this.colorStep.currentColor);
    }
}
