export default class Navigation {

    private readonly openBodyClass = 'nav-open';
    private readonly openClass = 'open';
    private container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.container.querySelectorAll('.nav-link').forEach(link => link.addEventListener('click', e => this.handleClick()))
    }

    public open() {
        window.scrollTo(0, 0);
        this.container.classList.add(this.openClass);
        document.body.classList.add(this.openBodyClass);
    }

    public close() {
        this.container.classList.remove(this.openClass);
        document.body.classList.remove(this.openBodyClass);
    }

    private handleClick() {
        this.close();
    }

}