import Navigation from "./Navigation";
import ScrollToTop from "./ScrollToTop";
import PlausibleEvents from "./PlausibleEvents";
import Configurator from "./Configurator";
import { Login } from "./Login";
import { PasswordHandle } from "./PasswordHandle";
import TabSwitcher from "./TabSwitcher";
import ProfileOverlay from "./ProfileOverlay";
import SelectAddress from "./SelectAddress";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// navigation
const navContainer = <HTMLElement>document.querySelector(".nav");
const nav = new Navigation(navContainer);

// this is necessary, because clicks apparently aren't always fired on ios
// see https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html for details
const eventType = "ontouchstart" in window ? "touchstart" : "click";
document.querySelector(".nav-open").addEventListener(eventType, () => nav.open());
document.querySelector(".nav-close").addEventListener(eventType, () => nav.close());

// scroll to top
const scrollToTopButton = <HTMLElement>document.querySelector("#BackToTop");
new ScrollToTop(scrollToTopButton);
new PlausibleEvents();

// configurator
const configuratorContainer = <HTMLElement>document.querySelector("#Configurator");
if (configuratorContainer) {
    new Configurator(configuratorContainer);
}

// login
const loginForm = <HTMLFormElement>document.querySelector("#Login form");
if (loginForm) {
    new Login(loginForm);
}

// Show and Hide password
const passwordButtons = <NodeListOf<HTMLButtonElement>>document.querySelectorAll("button.password");
if (passwordButtons) {
    new PasswordHandle(passwordButtons);
}

// Profile scripts
const profile = <HTMLElement>document.querySelector("#Profile");
if (profile) {
    new TabSwitcher(profile);
    new ProfileOverlay(profile);
}

// Select Address Functionality
const changeBillingAddressButton = document.querySelector(".change-billing-address");
const billingAddressOverlay = document.querySelector(".overlay-change-billing-address");
if (changeBillingAddressButton && billingAddressOverlay) {
    new SelectAddress(changeBillingAddressButton, billingAddressOverlay, "billingAddress");
}
const changeShippingAddressButton = document.querySelector(".change-shipping-address");
const shippingAddressOverlay = document.querySelector(".overlay-change-shipping-address");
if (changeShippingAddressButton && shippingAddressOverlay) {
    new SelectAddress(changeShippingAddressButton, shippingAddressOverlay, "shippingAddress");
}
