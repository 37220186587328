import { applyDiscount, calculateCost } from "./utility/helper";

type ItemColor = {
    id: string;
    code: string;
    name: string;
    configRollCost: number;
    configCutCost: number;
    configOrderCost: number;
    deliveryTime: string;
    image: string;
    pricePerSquareMeter: number;
    pricePerSquareMeterRoll: number;
    rollLength: number;
    thickness: number;
    height: number;
    specialHeight: number;
    product: string;
    specialSizeSelected: boolean;
};

type ItemAccessory = {
    id: string;
    name: string;
    price: number;
    amount: string;
    deliveryTime: string;
    image: string;
};

interface Item {
    id: string;
    color?: ItemColor;
    accessory?: ItemAccessory;
    amount: {
        rolls?: number;
        lfm?: number;
        sample?: number;
        piece?: number;
    };
    cost: {
        rolls?: number;
        lfm?: number;
        piece?: number;
        totalConfigCost?: number;
    };
}

class Cart {
    private cookieName = "mein-linoleum-warenkorb";
    private cart: Item[] = [];

    constructor() {
        const cookies = decodeURIComponent(document.cookie).split("; ");
        cookies.forEach((cookie) => {
            if (cookie.startsWith(this.cookieName + "=")) {
                this.cart = JSON.parse(cookie.replace(this.cookieName + "=", ""));
            }
        });
    }

    public addToCart(entry: Item) {
        let cartItem: Item;
        this.cart.forEach((item) => {
            if (item.id === entry.id) {
                if (entry.amount.rolls) {
                    if (!item.amount.rolls) {
                        item.amount.rolls = entry.amount.rolls;
                    } else {
                        item.amount.rolls += entry.amount.rolls;
                    }
                }
                if (entry.amount.lfm) {
                    item.amount.lfm = entry.amount.lfm;
                }
                if (entry.amount.sample) {
                    item.amount.sample = entry.amount.sample;
                }
                if (entry.amount.piece) {
                    item.amount.piece += entry.amount.piece;
                }
                this.addCost(item);
                cartItem = item;
            }
        });

        if (!cartItem) {
            this.addCost(entry);
            this.cart.push(entry);
        }

        this.saveCart();
        return cartItem ? cartItem : entry;
    }

    private addCost(item: Item) {
        item.cost.totalConfigCost = 0;
        let height = 0;
        if (item.amount.rolls || item.amount.lfm) {
            height = item.color.specialSizeSelected ? item.color.specialHeight : item.color.height;
        }
        if (item.amount.rolls) {
            item.cost.rolls = calculateCost(
                item.color.rollLength,
                item.amount.rolls,
                height,
                item.color.pricePerSquareMeterRoll
            );
            item.cost.totalConfigCost += item.color.configRollCost;
        }
        if (item.amount.lfm) {
            item.cost.lfm = calculateCost(item.amount.lfm, 1, height, item.color.pricePerSquareMeter);
            item.cost.totalConfigCost += item.color.configCutCost;
        }
        if (item.amount.piece) {
            item.cost.piece = item.accessory.price * item.amount.piece;
        }
        if (item.color) {
            item.cost.totalConfigCost += item.color.configOrderCost;
        }
        if (item.amount.sample > 0 && !item.amount.rolls && !item.amount.lfm) {
            item.cost.totalConfigCost = 0;
        }
    }

    public removeFromCart(itemId: string) {
        this.cart = this.cart.filter((cartItem) => cartItem.id !== itemId);
        this.saveCart();
    }

    public deleteCart() {
        this.cart = [];
        document.cookie = `${this.cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }

    public getCart() {
        return this.cart;
    }

    private saveCart() {
        document.cookie = `${this.cookieName}=${JSON.stringify(this.cart)}; ${Cart.getExpirationString()}`;
    }

    private static getExpirationString() {
        const now = new Date();
        now.setTime(now.getTime() + 7 * 24 * 60 * 60 * 1000);
        return "expires=" + now.toUTCString();
    }
}

export { Cart, Item };
